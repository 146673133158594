
const awsmobile = {
    "aws_project_region": "eu-central-1",
//    "aws_cognito_identity_pool_id": "eu-central-1:b32320ac-b06c-4293-bf63-5d887729f2b7",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_kgql64GGD",
    "aws_user_pools_web_client_id": "5mimf10t3pagp7cttklgst2mir",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ] /*,
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL" 
    ]*/
};


export default awsmobile;
