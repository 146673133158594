<script>
import { Auth } from 'aws-amplify';

export default {
    name: "Own-invoices-page",
    data(){
        return {
            ir: {},
            invoiceError: '',
            idtoken: '',
            timer: '',
            showtable: false
        }
    },
    async created() {
        this.idtoken = (await Auth.currentSession()).getIdToken().getJwtToken();
        this.loadFetch();
        this.timer = setInterval(this.loadFetch,5000);
    },
    methods: {
        loadFetch(){
            const headers = { 
              "Authorization" : this.idtoken
            }
            fetch( 'https://ui-api.dev.accepay.io/invoices/status', 
            {
              headers: headers
            }
            )
                .then( function( response ){
                    if( response.status != 200 ){
                        throw response.status;
                    }else{
                        this.showtable = true;
                        return response.json();
                    }
                }.bind(this))
                .then( function( data ){
                    this.ir = data;
                }.bind(this))
                .catch( function( error ){
                    this.invoiceError = error;
                }.bind(this));
        },
        parseCurrency(value, currency, locale) {
            return Number(value).toLocaleString(locale, { style: 'currency', currency: currency });
        },
        parseDate(value, locale) {
            const options = {dateStyle: 'short'};
            const date = new Date(value);
            return date.toLocaleString(locale, options);
        },
        parseStatus(status) {
          switch (status) {
            case 'created':
            case 'waiting_for_attachment':
            case 'ready_for_sending':
              return 'luotu';
            case 'send':
              return 'lähetetty';
            case 'email_read':
              return 'sähköposti luettu'
            case 'opened':
            case 'attachment_downloaded':
            case 'payment_started':
              return 'lasku avattu';
            case 'paid':
              return 'maksettu';
          }
          return 'tuntematon';
        }
    }
}
</script>
<template>
  <div class="invoices">
    <h3 v-if="!this.showtable">Ladataan laskujen tietoja...</h3>
    <table v-if="this.showtable">
      <tr>
        <th>#</th>
        <th>Y-tunnus</th>
        <th>Vastaanottaja</th>
        <th>Eräpäivä</th>
        <th>Tila</th>
      </tr>
      <tr v-for="item in ir.invoices" :key="item.invoiceId">
        <td>{{ item.invoiceNumber }}</td>
        <td>{{ item.invoiceRecipientPartyIdentifier }}</td>
        <td>{{ item.invoiceRecipientOrganisationName }} / {{ item.invoiceRecipientContactPersonName }}</td>
        <td>{{ parseDate(item.invoiceDueDate) }}</td>
        <td>{{ parseStatus(item.invoicestatus) }}</td>      
      </tr>
    </table>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.invoices {
  margin: 30px;
  align-items: left;
}

h3 {
  margin-left: 10px;
}

table {
  margin-left: 0px;
  border-collapse: collapse;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
}

table td {
  padding: 5px 10px 5px 10px;
  border: 2px solid #eff2fb;
}

th {
  background-color: #000e46;
  color: white;
  padding: 10px;
}
tr {
  background-color: #ffffff;
}
tr:nth-child(even) {background-color: #f6f6ff;}
</style>
